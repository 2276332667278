// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-katalog-tsx": () => import("../src/pages/katalog.tsx" /* webpackChunkName: "component---src-pages-katalog-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-produkt-tsx": () => import("../src/pages/produkt.tsx" /* webpackChunkName: "component---src-pages-produkt-tsx" */),
  "component---src-pages-szkolenia-tsx": () => import("../src/pages/szkolenia.tsx" /* webpackChunkName: "component---src-pages-szkolenia-tsx" */),
  "component---src-pages-video-tsx": () => import("../src/pages/video.tsx" /* webpackChunkName: "component---src-pages-video-tsx" */)
}

